import React from 'react';
import styled from 'styled-components';

import { Header } from 'components/header/Header';
import { HeaderLink } from 'components/header/HeaderLink';

import { responsiveFont } from 'styles/utils';
import { variables } from 'styles/variables';
import { Container } from 'components/container/Container';
import { Column } from 'components/grid/Column';
import { Row } from 'components/grid/Row';

import { Work } from 'components/work/Work';
import { WorkBlock } from 'components/work/WorkBlock';
import { WorkBlockImage } from 'components/work/WorkBlockImage';
import { WorkBlockTextLeft } from 'components/work/WorkBlockTextLeft';
import { WorkBlockTextRight } from 'components/work/WorkBlockTextRight';

import { graphql } from 'gatsby';

import Helmet from 'react-helmet';

import WorkVideoBevogClip from '../../assets/video/work_bevog.mp4';
import WorkVideoBevog from '../../assets/video/bevog_full.mp4';
import WorkVideoBevogPath from '../../assets/images/work_bevog.jpg';

import WorkVideoBB_Mega_baloons_Clip from '../../assets/video/work_BB_Mega_baloons_loop.mp4';
import WorkVideoBB_Mega_baloons from '../../assets/video/work_BB_Mega_baloons_full.mp4';
import WorkVideoVideoBB_Mega_baloonsPath from '../../assets/images/work_BB_Mega_baloons_image.jpeg';

import WorkVideoBBUAUClip from '../../assets/video/BigBang_UAU_clip.mp4';
import WorkVideoBBUAU from '../../assets/video/BigBang_UAU.mp4';
import WorkVideoBBUAUPath from '../../assets/images/work_BigBang_UAU.jpeg';

import WorkVideoBBUAUSP22Clip from '../../assets/video/BigBang_UAU_SP22_clip.mp4';
import WorkVideoBBUAUSP22 from '../../assets/video/BigBang_UAU_SP22.mp4';
import WorkVideoBBUAUSP22Path from '../../assets/images/work_BigBang_UAU_SP22.jpeg';

import WorkVideoBBUAUNSZClip from '../../assets/video/BigBang_UAU_NSZ_clip.mp4';
import WorkVideoBBUAUNSZ from '../../assets/video/BigBang_UAU_NSZ.mp4';
import WorkVideoBBUAUNSZPath from '../../assets/images/work_BigBang_UAU_NSZ.jpeg';

import WorkVideoGoogleArtsAndCultureClip from '../../assets/video/Google_Arts_And_Culture_clip.mp4';
import WorkVideoGoogleArtsAndCulture from '../../assets/video/Google_Arts_And_Culture.mp4';
import WorkVideoGoogleArtsAndCulturePath from '../../assets/images/work_Google_Arts_And_Culture.jpeg';

import WorkVideoSAMSUNGClip from '../../assets/video/SAMSUNG_clip.mp4';
import WorkVideoSAMSUNG from '../../assets/video/SAMSUNG.mp4';
import WorkVideoSAMSUNGPath from '../../assets/images/work_SAMSUNG.jpeg';

import WorkVideoMinoritiClip from '../../assets/video/work_minoriti_loop.mp4';
import WorkVideoMinoritiPath from '../../assets/images/work_Martin.jpg';

import WorkVideoZMTDubaiClip from '../../assets/video/ZMT_Dubai_clip.mp4';
import WorkVideoZMTDubai from '../../assets/video/ZMT_Dubai.mp4';
import WorkVideoZMTDubaiPath from '../../assets/images/work_ZMT_Dubai.jpeg';

import WorkVideoCryptoNowClip from '../../assets/video/work_Cryptonow_SocialPost.mp4';
import WorkVideoCryptoNowPath from '../../assets/images/work_Cryptonow.jpg';

import WorkVideoSOliverClip from '../../assets/video/SOliverxTrampolin_clip.mp4';
import WorkVideoSOliver from '../../assets/video/SOliverxTrampolin.mp4';
import WorkVideoSOliverPath from '../../assets/images/work_bevog.jpg';

import WorkVideoBB_3D2023Clip from '../../assets/video/work_BigBang_2023_3D_clip.mp4';
import WorkVideoBB_3D2023 from '../../assets/video/work_BigBang_2023_3D.mp4';
import WorkVideoBB_3D2023Path from '../../assets/images/work_BigBang_2023_3D_clip.jpg';

import WorkVideoNLBZlataLisica from '../../assets/video/NLB_ZlataLisica.mp4';
import WorkVideoNLBZlataLisicaPath from '../../assets/images/work_ZlataLisica_Ilustracija.jpg';

import WorkVideoStereosunClip from '../../assets/video/work_stereosun.mp4';
import WorkVideoStereosunPath from '../../assets/images/work_stereosun.jpg';
import WorkVideoStereosun from '../../assets/video/stereosun_full.mp4';

import WorkVideoBrightMP4 from '../../assets/video/work_bright.mp4';
import WorkVideoBrightPath from '../../assets/images/work_bright.jpg';

import WorkVideoFabulatorijClip from '../../assets/video/fabulatorij.mp4';
import WorkVideoFabulatorijPath from '../../assets/images/work_Fabulatorij.jpg';

import WorkVideoMartinClip from '../../assets/video/martin.mp4';
import WorkVideoMartinPath from '../../assets/images/work_Martin.jpg';

import WorkVideoAR9Clip from '../../assets/video/AR9.mp4';
import WorkVideoAR9Path from '../../assets/images/work_AR9.jpg';

import { useTranslation } from 'gatsby-plugin-react-i18next';
import { SimpleFooter } from 'components/footer/SimpleFooter';
import VideoPlayer from 'components/video/VideoPlayer';
import WorkFilter from 'components/work/WorkFilter';

import Img from 'gatsby-image';

// tslint:disable no-default-export

const Wrapper = styled.div`
  padding: 0px;
`;

const StrongText = styled.p`
  font-weight: 400;
  margin-bottom: 5px;
  font-size: 16px;
  margin-top: 15px;
  display: none;
`;

const AwardTitle = styled.p`
  margin-bottom: 5px;
  font-weight: 500;
`;

const AwardText = styled.p`
  margin-bottom: 5px;
  font-weight: 400;
  opacity: 0.5;
`;
const SectionTitle = styled.p`
  ${responsiveFont(38, 44)};

  font-family: ${variables.font.familyHeading};
  font-weight: 900;
  padding-bottom: 20px;
`;

export default ({ data, location }) => {
  const { t } = useTranslation();
  const playerRef = React.useRef<HTMLVideoElement>(null);
  const [fullscreen, setFullscreen] = React.useState(false);
  const [controls, setControls] = React.useState(true);

  const options = {
    preload: 'auto',
    controls: true,
    fullscreen: {
      options: {
        navigationUI: 'hide',
      },
    },
    userActions: {
      doubleClick: false,
    },
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    player.on('useractive', () => {
      setControls(true);
    });

    player.on('userinactive', () => {
      setControls(false);
    });

    player.on('ended', () => {
      setFullscreen(false);
      playerRef?.current?.currentTime(0);
    });

    player.on('fullscreenchange', () => {
      if (!player.isFullscreen()) {
        handleStop();
      }
    });
  };

  const handlePlay = (source) => {
    // @ts-ignore
    playerRef?.current?.src({ src: source, type: 'video/mp4' });
    setFullscreen(true);
    playerRef?.current?.play();
  };

  const handleStop = () => {
    setFullscreen(false);
    playerRef?.current?.pause();
  };

  return (
    <>
      <Header>
        <HeaderLink name={t('work')} href="/work" className="text-black" />
        <HeaderLink name={t('meet')} href="/contact" className="text-black" />
      </Header>
      <Helmet title="Work" />

      <Container>
        <Wrapper>
          <Row>
            <Column md={12} lg={12} sm={12}>
              <SectionTitle>{t('workTitle')}</SectionTitle>
              <WorkFilter location={location} />
            </Column>
          </Row>
        </Wrapper>
      </Container>

      <Work>
      <WorkBlock>
          <WorkBlockTextLeft
            title="Big Bang"
            services="Immersive 3D billboard"
            extras={[
              <StrongText>Awards</StrongText>,
              <AwardTitle>Diggit</AwardTitle>,
              <AwardText>Gold</AwardText>,
            ]}
          />
          <WorkBlockImage external="video" lg={8} md={8}>
            <video
              preload="auto"
              muted
              playsInline
              loop
              autoPlay
              className="w-full cursor-pointer"
              src={WorkVideoBB_Mega_baloons_Clip}
              onClick={() => handlePlay(WorkVideoBB_Mega_baloons)}
            />
          </WorkBlockImage>
        </WorkBlock>
        <WorkBlock>
          <WorkBlockTextLeft
            title="CryptoNow"
            services="Development"
            extras=""
          />
          <WorkBlockImage external="link" lg={8} md={8}>
            <video
              preload="auto"
              muted
              playsInline
              loop
              autoPlay
              className="w-full cursor-pointer"
              src={WorkVideoCryptoNowClip}
              onClick={() => window.open('https://www.cryptonow.ch', '_blank')}
            />
          </WorkBlockImage>
        </WorkBlock>
        <WorkBlock>
          <WorkBlockTextLeft
            title="CargoX"
            services="UX/UI Design, Development"
          />
          <WorkBlockImage external="link" lg={8} md={8}>
            <a href="https://cargox.io" target="_blank">
              <Img
                loading="eager"
                fluid={data.workImageCargoX.childImageSharp.fluid}
                alt="CargoX"
              />
            </a>
          </WorkBlockImage>
        </WorkBlock>
        <WorkBlock>
          <WorkBlockTextLeft
            title="Štajerska"
            services="Brand development"
            extras=""
          />
          <WorkBlockImage titleMobile="Štajerska Brand development" lg={8} md={8}>
            <Img
              loading="eager"
              fluid={data.workImageStajerska.childImageSharp.fluid}
              alt="Štajerska Brand development"
            />
          </WorkBlockImage>
        </WorkBlock>
        <WorkBlock>
          <WorkBlockTextLeft
            title="Verstovšek Estate"
            services="Brand development"
            extras=""
          />
          <WorkBlockImage titleMobile="Verstovšek Estate Brand development" lg={8} md={8}>
            <Img
              loading="eager"
              fluid={data.workImageVerstovsek.childImageSharp.fluid}
              alt="Verstovšek Estate Brand development"
            />
          </WorkBlockImage>
        </WorkBlock>
        <WorkBlock>
          <WorkBlockTextLeft
            title="Maribor Art Walk"
            services="Event visual identity"
            extras=""
          />
          <WorkBlockImage lg={8} md={8}>
            <Img
              loading="eager"
              fluid={data.workImageMAW.childImageSharp.fluid}
              alt="Maribor Art Walk"
            />
          </WorkBlockImage>
        </WorkBlock>
        <WorkBlock>
          <WorkBlockTextLeft title="Panorganix" services="In-store garden" extras="" />
          <WorkBlockImage titleMobile="Panorganix In-store garden" lg={8} md={8}>
            <Img
              loading="eager"
              fluid={data.workImagePanorganix.childImageSharp.fluid}
              alt="Panorganix In-store garden"
            />
          </WorkBlockImage>
        </WorkBlock>
        <WorkBlock>
          <WorkBlockTextLeft
            title="Big Bang"
            services="3D animated TVC"
            //extras="Production with Rok Frešer"
          />
          <WorkBlockImage external="video" lg={8} md={8}>
            <video
              preload="auto"
              muted
              playsInline
              loop
              autoPlay
              className="w-full cursor-pointer"
              src={WorkVideoBB_3D2023Clip}
              onClick={() => handlePlay(WorkVideoBB_3D2023)}
            />
          </WorkBlockImage>
        </WorkBlock>
        <WorkBlock>
          <WorkBlockTextLeft
            title="Big Bang"
            services="3D animated TVC"
            //extras="Production with Rok Frešer"
          />
          <WorkBlockImage external="video" lg={8} md={8}>
            <video
              preload="auto"
              muted
              playsInline
              loop
              autoPlay
              poster={WorkVideoBBUAUPath}
              className="w-full cursor-pointer"
              src={WorkVideoBBUAUClip}
              onClick={() => handlePlay(WorkVideoBBUAU)}
            />
          </WorkBlockImage>
        </WorkBlock>
        <WorkBlock>
          <WorkBlockTextLeft title="Bevog" services="Lola Packaging design" extras="" />
          <WorkBlockImage lg={8} md={8}>
            <Img
              loading="eager"
              fluid={data.workImageBevogLola.childImageSharp.fluid}
              alt="Bevog Lola Branding"
            />
          </WorkBlockImage>
        </WorkBlock>
        <WorkBlock>
          <WorkBlockTextLeft
            title="Fabulatorij"
            services="Branding, UX/UI Design, Development"
            extras={[
              <StrongText>Awards</StrongText>,
              <AwardTitle>CSSDesignAwards</AwardTitle>,
              <AwardText>Site of the day</AwardText>,
              <AwardTitle>Awwwards</AwardTitle>,
              <AwardText>Honorable Mention</AwardText>,
            ]}
          />
          <WorkBlockImage external="link" lg={8} md={8}>
            <video
              preload="auto"
              muted
              playsInline
              loop
              autoPlay
              className="w-full cursor-pointer"
              src={WorkVideoFabulatorijClip}
              onClick={() => window.open('https://www.fabulatorij.com', '_blank')}
            />
          </WorkBlockImage>
        </WorkBlock>
        <WorkBlock>
          <WorkBlockTextLeft
            title="Google Arts & Culture"
            services="Online film"
            extras={[
              <StrongText>Awards</StrongText>,
              <AwardTitle>The Golden City Gate</AwardTitle>,
              <AwardText>Platinum</AwardText>,
            ]}          />
          <WorkBlockImage external="video" lg={8} md={8}>
            <video
              preload="auto"
              muted
              playsInline
              loop
              autoPlay
              className="w-full cursor-pointer"
              src={WorkVideoGoogleArtsAndCultureClip}
              onClick={() => handlePlay(WorkVideoGoogleArtsAndCulture)}
            />
          </WorkBlockImage>
        </WorkBlock>
        <WorkBlock>
          <WorkBlockTextLeft
            title="ArtRebel9"
            services="UX/UI Design, Development"
            extras={[
              <StrongText>Awards</StrongText>,
              <AwardTitle>Awwwards</AwardTitle>,
              <AwardText>Honorable Mention</AwardText>,
            ]}
          />
          <WorkBlockImage external="link" lg={8} md={8}>
            <video
              preload="auto"
              muted
              playsInline
              loop
              autoPlay
              className="w-full cursor-pointer"
              src={WorkVideoAR9Clip}
              onClick={() => window.open('https://www.artrebel9.com', '_blank')}
            />
          </WorkBlockImage>
        </WorkBlock>
        <WorkBlock>
        <WorkBlockTextLeft
            title="Inhumanum"
            services="TV series Poster design"
            extras=""
          />
          <WorkBlockImage lg={5} md={5}>
            <Img fluid={data.workImageInhumanum.childImageSharp.fluid} alt="Inhumanum Poster" />
          </WorkBlockImage>
          <WorkBlockTextLeft
            title="Inhumanum"
            services="Poster design & illustration"
            extras=""
            mobileOnly="yes"
          />
          <WorkBlockImage lg={3} md={3}>
            <Img
              loading="eager"
              fluid={data.workImageHodnik.childImageSharp.fluid}
              alt="Hodnik Poster"
              style={{ width: '100%' }}
            />
          </WorkBlockImage>
          <WorkBlockTextRight
            title="Hodnik"
            services="Concept, Branding, Events"
            extras=""
          />
        </WorkBlock>
        <WorkBlock>
          <WorkBlockTextLeft
            title="Big Bang"
            services="3D animated TVC"
            //extras="Production with Rok Frešer"
          />
          <WorkBlockImage external="video" lg={8} md={8}>
            <video
              preload="auto"
              muted
              playsInline
              loop
              autoPlay
              className="w-full cursor-pointer"
              src={WorkVideoBBUAUSP22Clip}
              onClick={() => handlePlay(WorkVideoBBUAUSP22)}
            />
          </WorkBlockImage>
        </WorkBlock>
        <WorkBlock>
          <WorkBlockTextLeft
            title="Cultural district Minoriti"
            services="UX/UI Design, Development"
            extras=""
          />
          <WorkBlockImage external="link" lg={8} md={8}>
            <video
              preload="auto"
              muted
              playsInline
              loop
              autoPlay
              className="w-full cursor-pointer"
              src={WorkVideoMinoritiClip}
              onClick={() => window.open('https://www.minoriti.si', '_blank')}
            />
          </WorkBlockImage>
        </WorkBlock>
        <WorkBlock>
          <WorkBlockTextLeft
            title="Radio Center"
            services="UX/UI Design, Development"
          />
          <WorkBlockImage external="link" lg={8} md={8}>
            <a href="https://radiocenter.si" target="_blank">
              <Img
                loading="eager"
                fluid={data.workImageHRC.childImageSharp.fluid}
                alt="Radio Center"
              />
            </a>
          </WorkBlockImage>
        </WorkBlock>
        <WorkBlock>
          <WorkBlockTextLeft
            title="Parson Premium Gin"
            services="Branding and packaging design"
            extras={[
              <StrongText>Awards</StrongText>,
              <AwardTitle>Graphis</AwardTitle>,
              <AwardText>Gold</AwardText>,
              <AwardTitle>World Gin Awards</AwardTitle>,
              <AwardText>Bronze</AwardText>,
            ]}
          />
          <WorkBlockImage lg={8} md={8}>
            <Img
              loading="eager"
              fluid={data.workImageParsonsGin.childImageSharp.fluid}
              alt="Parson Branding"
            />
          </WorkBlockImage>
        </WorkBlock>
        <WorkBlock>
          <WorkBlockTextLeft
            title="Malner cider"
            services="Packaging design"
            extras={[
              <StrongText>Awards</StrongText>,
              <AwardTitle>Graphis</AwardTitle>,
              <AwardText>Silver</AwardText>,
            ]}
          />
          <WorkBlockImage lg={8} md={8}>
            <Img
              loading="eager"
              fluid={data.workImageMalner.childImageSharp.fluid}
              alt="Malner Cider Branding"
            />
          </WorkBlockImage>
        </WorkBlock>
        <WorkBlock>
          <WorkBlockTextLeft
            title="Stereosun"
            services="Concept, UX/UI Design, iOS Development"
            extras="Collaboration with Matija Kocbek"
          />
          <WorkBlockImage external="video" lg={8} md={8}>
            <video
              id="videoStereosun"
              preload="auto"
              muted
              playsInline
              loop
              autoPlay
              onClick={() => handlePlay(WorkVideoStereosun)}
              poster={WorkVideoStereosunPath}
              className="w-full cursor-pointer"
            >
              <source src={WorkVideoStereosunClip} type="video/mp4" />
            </video>
          </WorkBlockImage>
        </WorkBlock>
        <WorkBlock>
          <WorkBlockTextLeft
            title="Bevog"
            services="Buzz Packaging design"
            extras={[
              <StrongText>Awards</StrongText>,
              <AwardTitle>Favourite Design</AwardTitle>,
              <AwardText>Design of the day</AwardText>,
            ]}
          />
          <WorkBlockImage lg={8} md={8}>
            <Img
              loading="eager"
              fluid={data.workImageBevogBuzz.childImageSharp.fluid}
              alt="Bevog Buzz Branding"
            />
          </WorkBlockImage>
        </WorkBlock>
        <WorkBlock>
          <WorkBlockTextLeft title="Becycle" services="Hotel Branding" extras="" />
          <WorkBlockImage lg={8} md={8}>
            <Img
              loading="eager"
              fluid={data.workImageBecycle.childImageSharp.fluid}
              alt="Becycle Branding"
            />
          </WorkBlockImage>
        </WorkBlock>
        <WorkBlock>
          <WorkBlockTextLeft title="Bevog" services="Nitro Packaging" extras="" />
          <WorkBlockImage lg={8} md={8}>
            <Img
              loading="eager"
              fluid={data.workImageBevogNoBgBg.childImageSharp.fluid}
              alt="Bevog Nitro Packaging"
              style={{ width: '100%', position: 'relative' }}
            />
          </WorkBlockImage>
        </WorkBlock>
        <WorkBlock>
          <WorkBlockTextLeft title="MOL" services="Poster design & illustration" extras={[
              <StrongText>Awards</StrongText>,
              <AwardTitle>Outstanding</AwardTitle>,
              <AwardText>Gold</AwardText>,
            ]} />
          <WorkBlockImage lg={5} md={5}>
            <Img fluid={data.workImageMOL.childImageSharp.fluid} alt="MOL Trees Poster" />
          </WorkBlockImage>
          <WorkBlockImage lg={3} md={3}>
            <Img
              loading="eager"
              fluid={data.workImageMOLCebele.childImageSharp.fluid}
              alt="MOL Čebele Poster"
              style={{ width: '100%' }}
            />
          </WorkBlockImage>
          <WorkBlockTextRight
            title="MOL Čebele"
            services="Poster design & illustration"
            extras=""
          />
        </WorkBlock>
        <WorkBlock>
          <WorkBlockTextLeft
            title="Fair From the Sofa"
            services="Poster design & illustration"
            extras=""
          />
          <WorkBlockImage lg={8} md={8}>
            <Img
              loading="eager"
              fluid={data.workImageSejem2.childImageSharp.fluid}
              alt="Fair From the Sofa"
            />
          </WorkBlockImage>
        </WorkBlock>
        <WorkBlock>
          <WorkBlockTextLeft title="S.Oliver" services="Showroom design" extras="" />
          <WorkBlockImage external="video" lg={8} md={8}>
            <video
              preload="auto"
              muted
              playsInline
              loop
              autoPlay
              className="w-full cursor-pointer"
              src={WorkVideoSOliverClip}
              onClick={() => handlePlay(WorkVideoSOliver)}
            />
          </WorkBlockImage>
        </WorkBlock>
        <WorkBlock>
          <WorkBlockTextLeft
            title="Big Bang"
            services="3D animated TVC"
            //extras="Production with Rok Frešer"
          />
          <WorkBlockImage external="video" lg={8} md={8}>
            <video
              preload="auto"
              muted
              playsInline
              loop
              autoPlay
              className="w-full cursor-pointer"
              src={WorkVideoBBUAUNSZClip}
              onClick={() => handlePlay(WorkVideoBBUAUNSZ)}
            />
          </WorkBlockImage>
        </WorkBlock>
        <WorkBlock>
          <WorkBlockTextLeft title="Martin Smerdel" services="UX/UI Design, Development" />
          <WorkBlockImage external="link" lg={8} md={8}>
            <video
              preload="auto"
              muted
              playsInline
              loop
              autoPlay
              className="w-full cursor-pointer"
              src={WorkVideoMartinClip}
              onClick={() => window.open('https://www.martinsmerdel.com', '_blank')}
            />
          </WorkBlockImage>
        </WorkBlock>

        <WorkBlock>
          <WorkBlockTextLeft
            title="NLB"
            services="Innovative intergated campaign"
            extras={[
              <p>With Internavti</p>,
              <br />,
              <StrongText>Awards</StrongText>,
              <AwardTitle>SOF</AwardTitle>,
              <AwardText>Gold and Silver</AwardText>,
            ]}
          />
          <WorkBlockImage external="video" lg={8} md={8}>
            <div
              onClick={() => handlePlay(WorkVideoNLBZlataLisica)}
              className="w-full cursor-pointer"
            >
              <Img
                loading="eager"
                fluid={data.workImageZlataLisica.childImageSharp.fluid}
                alt="Zlata Lisica"
              />
            </div>
          </WorkBlockImage>
        </WorkBlock>

        <WorkBlock>
          <WorkBlockTextLeft
            title="Bright"
            services="UX/UI Design, Development"
            extras="With Bright Visuals and Dragan Petoš"
          />
          <WorkBlockImage external="link" lg={8} md={8}>
            <video
              preload="auto"
              muted
              playsInline
              loop
              autoPlay
              className="w-full cursor-pointer"
              src={WorkVideoBrightMP4}
              onClick={() => window.open('https://www.brightvisuals.com', '_blank')}
            />
          </WorkBlockImage>
        </WorkBlock>

        <WorkBlock>
          <WorkBlockTextLeft
            title="Maribor Turist Board"
            services="Film about Maribor for Expo Dubai"
            // extras="With Rok Frešer and Jaganath Production"
          />
          <WorkBlockImage external="video" lg={8} md={8}>
            <video
              preload="auto"
              muted
              playsInline
              loop
              autoPlay
              className="w-full cursor-pointer"
              src={WorkVideoZMTDubaiClip}
              onClick={() => handlePlay(WorkVideoZMTDubai)}
            />
          </WorkBlockImage>
        </WorkBlock>
        <WorkBlock>
          <WorkBlockTextLeft
            title="Bevog"
            services="Nitro Films"
            //extras="Production with Rok Frešer"
          />
          <WorkBlockImage external="video" lg={8} md={8}>
            <video
              preload="auto"
              muted
              playsInline
              loop
              autoPlay
              className="w-full cursor-pointer"
              src={WorkVideoBevogClip}
              onClick={() => handlePlay(WorkVideoBevog)}
            />
          </WorkBlockImage>
        </WorkBlock>
        <WorkBlock>
          <WorkBlockTextLeft
            title="Joan"
            services="UX/UI Design"
            //extras=""
          />
          <WorkBlockImage external="link" lg={8} md={8}>
            <a href="https://getjoan.com" target="_blank">
              <Img loading="eager" fluid={data.workImageJoan.childImageSharp.fluid} alt="Joan" />
            </a>
          </WorkBlockImage>
        </WorkBlock>
        <WorkBlock>
          <WorkBlockTextLeft
            title="Samsung"
            services="Immersive 3D billboard"

          />
          <WorkBlockImage external="video" lg={8} md={8}>
            <video
              preload="auto"
              muted
              playsInline
              loop
              autoPlay
              className="w-full cursor-pointer"
              src={WorkVideoSAMSUNGClip}
              onClick={() => handlePlay(WorkVideoSAMSUNG)}
            />
          </WorkBlockImage>
        </WorkBlock>
      </Work>
      <Container>
        <Wrapper>
          <Row>
            <Column sm={12}>
              <SimpleFooter />
            </Column>
          </Row>
        </Wrapper>
      </Container>
      {/* fullscreen Video */}
      <div
        className={
          !fullscreen
            ? 'opacity-0 invisible transition-opacity w-0 h-0'
            : 'opacity-100 visible z-10 w-full h-screen top-0 left-0 transition-opacity fixed'
        }
      >
        <VideoPlayer options={options} onReady={handlePlayerReady} />
        <button
          className={`${
            !controls ? 'opacity-0 delay-500' : 'opacity-100'
          } transition-opacity duration-700  absolute right-2 top-2 w-10 h-10 border text-white`}
          onClick={handleStop}
        >
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.5893 8.45868L15.932 14.1147L10.276 8.45868L8.39062 10.344L14.0466 16L8.39062 21.656L10.276 23.5413L15.932 17.8853L21.5893 23.5413L23.4746 21.656L17.8186 16L23.4746 10.344L21.5893 8.45868Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
      {/* / fullscreen Video */}
    </>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "contact", "index", "terms", "work"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    workImagePanorganix: file(relativePath: { eq: "images/work_Panorganix.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageHodnik: file(relativePath: { eq: "images/work_hodnik1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 430, maxHeight: 602, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageBecycle: file(relativePath: { eq: "images/work_becycle.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageBevog: file(relativePath: { eq: "images/work_bevog_beers.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageJoan: file(relativePath: { eq: "images/work_joan.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageBorstnikovo: file(relativePath: { eq: "images/work_borstnikovo.png" }) {
      childImageSharp {
        fluid(maxWidth: 430, maxHeight: 602, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageBevogNoBg: file(relativePath: { eq: "images/work_bevog_beers_nobg.png" }) {
      childImageSharp {
        fluid(maxWidth: 860, maxHeight: 484, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageBevogNoBgBg: file(relativePath: { eq: "images/work_bevog_beers_nobg_bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 860, maxHeight: 484, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageArtRebel: file(relativePath: { eq: "images/work_ArtRebel.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageBevogBuzz: file(relativePath: { eq: "images/work_Bevog_Buzz.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageBevogLola: file(relativePath: { eq: "images/work_Bevog_Lola.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 100) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageBevogLola2: file(relativePath: { eq: "images/work_Bevog_Lola_2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 100) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageBigBangImmersive3D: file(relativePath: { eq: "images/work_BigBang_Immersive3D.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageBigBangMega: file(relativePath: { eq: "images/work_BigBang_Mega.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageBigBangSC22: file(relativePath: { eq: "images/work_BigBang_SC22_2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageBigBangUAUNSZ: file(relativePath: { eq: "images/work_BigBang_UAU_NSZ.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageBigBangUAUSP22: file(relativePath: { eq: "images/work_BigBang_UAU_SP22.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageBigBangUAU: file(relativePath: { eq: "images/work_BigBang_UAU.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 800, maxHeight: 600, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageFab: file(relativePath: { eq: "images/work_Fab.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageFab2: file(relativePath: { eq: "images/work_Fab_2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageGAAC: file(relativePath: { eq: "images/work_Google_Arts_And_Culture_clip.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageHRC: file(relativePath: { eq: "images/work_HRC.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 100) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageMalner: file(relativePath: { eq: "images/work_Malner.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageMalner2: file(relativePath: { eq: "images/work_Malner_2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, maxHeight: 600, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageMOL: file(relativePath: { eq: "images/work_MOL_1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 539, maxHeight: 800, quality: 100) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageMOL2: file(relativePath: { eq: "images/work_MOL_2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 539, maxHeight: 800, quality: 100) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageParsonsGin: file(relativePath: { eq: "images/work_ParsonsGIn.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 0) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageSamsung: file(relativePath: { eq: "images/work_SAMSUNG.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageSamsung2: file(relativePath: { eq: "images/work_Samsung_2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, maxHeight: 600, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageSejem: file(relativePath: { eq: "images/work_Sejem_.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 560, maxHeight: 800, quality: 100) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageSejem2: file(relativePath: { eq: "images/work_Sejem_2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 560, maxHeight: 800, quality: 100) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageZDL: file(relativePath: { eq: "images/work_ZDL.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageZlataLisica: file(relativePath: { eq: "images/work_ZlataLisica_Ilustracija.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageZMTDubai: file(relativePath: { eq: "images/work_ZMT_Dubai.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageBright: file(relativePath: { eq: "images/work_bright.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageStereosun: file(relativePath: { eq: "images/work_stereosun.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageNLBLisica: file(relativePath: { eq: "images/work_ZlataLisica_Ilustracija.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageZMTDubai2: file(relativePath: { eq: "images/work_ZMT_Dubai_2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageInhumanum: file(relativePath: { eq: "images/work_inhumanum.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 540, maxHeight: 760, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageCargoX: file(relativePath: { eq: "images/work_CargoX.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageCryptoNow: file(relativePath: { eq: "images/work_Cryptonow.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageUrbana: file(relativePath: { eq: "images/work_Urbana_2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageStajerska: file(relativePath: { eq: "images/work_Stajerska.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageVerstovsek: file(relativePath: { eq: "images/work_Verstovsek.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageMAW: file(relativePath: { eq: "images/work_ArtWalk.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 850, maxHeight: 478, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
    workImageMOLCebele: file(relativePath: { eq: "images/work_mol_cebele.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 560, maxHeight: 800, quality: 70) {
          base64
          aspectRatio
          src
          srcSet
          srcWebp
          srcSetWebp
          sizes
        }
      }
    }
  }
`;
